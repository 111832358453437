import React from "react";
import snarkdown from "snarkdown";
import styled from "styled-components";

const MarkdownContainer = styled.div`
  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    all: revert;
  }
  white-space: pre-line;
  strong {
    font-weight: bold;
  }
  img {
    height: 16px;
    margin: 0;
    vertical-align: text-bottom;
  }
  a {
    text-decoration: underline;
  }
  s {
    display: inline-block;
    position: relative;
    text-decoration: none;
  }
  s:after {
    border-top: 2px solid red;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(50% - 1px);
    width: 100%;
    transform: rotateZ(-4deg);
  }
  word-wrap: break-word;
  font-family: inherit;
  ul {
    margin-top: 8px;
    margin-left: 18px;
  }
  li {
    list-style-type: disc;
    margin-bottom: 8px;
  }
  br {
    margin-bottom: 20px;
  }
  ${(props) => ({ ...props.styling })};
  p {
    max-width: 100%;
    text-align: justify;
    margin: 16 auto;
    padding-bottom: 0;
    ${(props) => ({ ...props.styling })};
  }
  a {
    color: black;
    &:visited {
      color: black;
    }
  }
`;

const MarkdownRender = ({ styling = {}, source = "" }) => {
  return (
    <MarkdownContainer
      styling={styling}
      dangerouslySetInnerHTML={{
        __html: `<p>${snarkdown(source)}</p>`,
      }}
    />
  );
};

export default MarkdownRender;
