import React from 'react'
import Router from 'next/router'
import { HEXCOLORS } from '../styles/index'
import styled from 'styled-components'
import Icons from './contact/Icons'
import MarkdownRender from './MarkdownRender'
import Flex from './Flex'
import { Phone } from 'react-feather'

const Container = styled.div`
  background-color: rgba(32, 45, 58, 1);
  padding: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding-bottom: 0;
`

const Logo = styled.img`
  width: 200px;
`

const Flex2 = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  @media all and (max-width: 768px) {
    display: block;
    width: 100%;
  }
`

const Item2 = styled.div`
  padding: 0 16px;
`

const SubHeading = styled.p`
  font-weight: 600;
  font-family: Nexa;
  color: white;
  margin-bottom: 8px;
`

const Small = styled.div`
  margin: 0;
  font-size: 12px;
  a {
    text-decoration: underline;
    color: ${HEXCOLORS.white};
  }
`

const Nakamas = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(32 45 58);
  padding: 32px;
  color: white;
  font-size: 12px;
`

const Flexy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  font-size: 10px;
`

const NakamasLogo = styled.img`
  height: 16px !important;
  width: 64px !important;
  margin-left: 4px;
  margin-top: 2px;
`

const Link = styled.a``

const Footer = ({ content }) => {
  return (
    <>
      <Container>
        <Logo
          src='/images/logo-white.svg'
          className='logo'
          alt='Kine Itterbeek'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            Router.push('/')
          }}
        />
        <br />
        <Flex2>
          <Item2>
            <SubHeading>Kine Itterbeek</SubHeading>
            <Small>
              <a
                href='https://www.google.com/maps/dir/?api=1&destination=Kerkstraat+40,+1701+Itterbeek&hl=nl'
                target='_blank'
                rel='noopener noreferrer'
                alt='google maps'
              >
                {content.adres}
              </a>
            </Small>
            <Small>BE07 3457 5852</Small>
            <Small>BE14 7360 6239 2383</Small>
          </Item2>
          <Item2>
            <SubHeading>Openingsuren</SubHeading>
            <Small>
              <MarkdownRender
                styling={{ margin: '0' }}
                source={content.openingsuren}
              />
            </Small>
          </Item2>
          <Item2>
            <SubHeading>Email</SubHeading>
            <Small>
              <a
                href={`mailto:${content.email}kine.itterbeek@gmail.com`}
                alt='email'
              >
                {content.email}
              </a>
            </Small>
            <SubHeading>Telefoon</SubHeading>
            <Small>
              <Flex style={{ flexDirection: 'column', alignItems: 'start' }}>
                {content.team.map(member => (
                  <Link
                    href={`tel:${member.tel}`}
                    target='_blank'
                    alt='tel'
                    name='tel'
                    rel='noreferrer'
                    key={member.id}
                  >
                    <name>{member.naam}</name>&nbsp;-&nbsp;
                    <span>{member.tel}</span>
                  </Link>
                ))}
              </Flex>
            </Small>
          </Item2>
        </Flex2>
        <br />
        <Icons content={content} isSmall />
      </Container>
      <Nakamas>
        © Kine Itterbeek 2023
        <Flexy>
          <span>webstudio</span>
          <a
            href='https://nakamas.be'
            alt='Nakamas webstudio'
            target='_blank'
            rel='noreferrer'
          >
            <NakamasLogo
              className='logo-footer'
              src='/images/nakamas.svg'
              alt='Nakamas
            webstudio'
            />
          </a>
        </Flexy>
      </Nakamas>
    </>
  )
}

export default Footer
