import React from 'react'
import styled from 'styled-components'
import Anchor from '../Anchor'
import MarkdownRender from '../MarkdownRender'

const Wrapper = styled.div`
  padding: 48px 0;
  margin: 48px auto;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch; /* Ensures all children have the same height */
  justify-content: space-between; /* Adjust this as needed */
  width: 100vw;
  background-image: radial-gradient(
      at 85% 56%,
      hsla(57, 81%, 71%, 0.23) 0px,
      transparent 50%
    ),
    radial-gradient(at 21% 67%, hsla(286, 73%, 90%, 0.19) 0px, transparent 50%),
    radial-gradient(at 94% 68%, hsla(273, 83%, 75%, 0.19) 0px, transparent 50%),
    radial-gradient(at 62% 41%, hsla(150, 62%, 79%, 0.06) 0px, transparent 50%),
    radial-gradient(at 94% 2%, hsla(341, 81%, 64%, 0.14) 0px, transparent 50%),
    radial-gradient(at 63% 97%, hsla(266, 69%, 91%, 0.21) 0px, transparent 50%),
    radial-gradient(at 54% 37%, hsla(129, 61%, 60%, 0.11) 0px, transparent 50%),
    radial-gradient(at 75% 45%, hsla(260, 72%, 60%, 0.2) 0px, transparent 50%),
    radial-gradient(at 40% 80%, hsla(280, 64%, 76%, 0.15) 0px, transparent 50%);

  @media all and (max-width: 768px) {
    padding: 32px 16px;
  }
`

const News = styled.div`
  padding: 32px 32px 48px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  justify-content: stretch;
  flex-direction: row;
  max-width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent line breaks in children */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Microsoft Edge */
  ::-webkit-scrollbar {
    display: none; /* Hide scrollbar for WebKit-based browsers like Chrome and Safari */
  }

  @media all and (max-width: 768px) {
    padding: 32px 16px;
  }
`

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  padding: 32px;
  margin: 8px;
  flex: 1;
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  text-wrap: wrap;
  width: 33vw; /* Each child takes 75% of the viewport width */
  min-width: 33vw; /* Ensure a minimum width for each child */
  border-radius: 16px;

  @media all and (max-width: 1280px) {
    width: 66vw; /* Each child takes 75% of the viewport width */
    min-width: 66vw; /* Ensure a minimum width for each child */
  }
`

const Heading = styled.h3`
  font-size: 18px;
  margin-bottom: 0;
`

const Heading2 = styled.h3`
  font-size: 24px;
  margin-bottom: 0;
`

const Date = styled.div`
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

const Nieuws = ({ content }) => {
  return content?.length > 0 ? (
    <>
      <Anchor name='nieuws' />
      <Wrapper>
        <Heading2>Nieuws</Heading2>
        <News>
          {content.map((item, index) => {
            const parts = item.createdAt.split('T')
            const stringWithoutTAndFollowing = parts[0]
            return (
              <Item key={index} items={item}>
                <Date>{stringWithoutTAndFollowing}</Date>
                <Heading>{item.titel}</Heading>
                <MarkdownRender
                  source={item.beschrijving}
                  styling={{ textAlign: 'left' }}
                />
              </Item>
            )
          })}
        </News>
      </Wrapper>
    </>
  ) : null
}

export default Nieuws
