import React from 'react'
import {
  ChevronDown,
  ChevronUp,
  Minus,
  MinusCircle,
  Plus,
  PlusCircle,
} from 'react-feather'
import styled, { css } from 'styled-components'
import { HEXCOLORS } from '../styles'
import MarkdownRender from './MarkdownRender'

const Container = styled.div`
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 16px;
  width: 100%;
  max-width: 768px;
  overflow: hidden;
  text-align: left;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
`

const Heading = styled.h3`
  width: 100%;
  margin: 0;
  margin-bottom: -10px;
  ${props =>
    props.isActive &&
    css`
      color: ${HEXCOLORS.text};
    `};
  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: -4px;
  }
`

const Item = styled.div`
  padding: 32px;
  background-color: ${HEXCOLORS.white};
  border-top: 1px solid #e7e6eb;
  &:first-child {
    border-top: 0;
  }
  ${props =>
    props.isActive &&
    css`
      background-color: rgba(255, 255, 255, 0.6);
    `};

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 24px;
  }
`

const Icon = styled.div`
  flex-shrink: 1;
`

const Extra = styled.div`
  color: ${HEXCOLORS.primary};
  font-size: 14px;
`

const MinusIcon = styled.span`
  background-color: ${HEXCOLORS.primary};
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PlusIcon = styled.span`
  border: 1px solid #e7e6eb;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Accordion = ({ items }) => {
  const [active, setActive] = React.useState()

  const isActive = id => {
    return id === active
  }

  return (
    <Container>
      {items.map(item => (
        <Item key={item.id} isActive={isActive(item.id)}>
          <Header
            onClick={() => {
              if (active === item.id) {
                setActive(null)
              } else {
                setActive(item.id)
              }
            }}
            isActive={isActive(item.id)}
          >
            <Heading isActive={isActive(item.id)}>{item.titel}</Heading>
            <Icon>
              {active === item.id ? (
                <MinusIcon>
                  <ChevronUp size='16' color='white' />
                </MinusIcon>
              ) : (
                <PlusIcon>
                  <ChevronDown size='16' color={HEXCOLORS.text} />
                </PlusIcon>
              )}
            </Icon>
          </Header>
          {active === item.id && (
            <>
              <MarkdownRender source={item.beschrijving} />
              {item.extra && (
                <Extra>
                  <MarkdownRender source={item.extra} />
                </Extra>
              )}
            </>
          )}
        </Item>
      ))}
    </Container>
  )
}

export default Accordion
