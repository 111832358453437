import styled from "styled-components";

const Anchor = styled.div`
    display: block;
    position: relative;
    top: -45px; 
    visibility: hidden;
`;

export default Anchor;
