import styled from "styled-components";

const Flex = styled.div`
  display: flex;
  justify-content: center;
  text-align: left;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FlexStart = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-left: 32px;
  text-align: left;
  width: 40%;
  @media all and (max-width: 768px) {
    width: 100% !important;
    padding: 32px;
    margin-left: 0;
  }
`;

export default Flex;
