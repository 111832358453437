import React from 'react'
import styled from 'styled-components'
import Accordion from '../Accordion'
import Anchor from '../Anchor'

const Wrapper = styled.div`
  padding: 32px 32px 48px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media all and (max-width: 768px) {
    padding: 32px 16px;
  }
`

const Heading = styled.h2``

const Behandelingen = ({ content }) => {
  return (
    <>
      <Anchor name="behandelingen" />
      <Wrapper>
        <Heading>Onze behandelingen</Heading>
        <Accordion items={content} />
      </Wrapper>
    </>
  )
}

export default Behandelingen
