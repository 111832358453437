import React from "react";
import styled from "styled-components";

const Container = styled.div`
  text-align: ${(props) => props.align};
`;

const Align = ({ align = "left", children }) => {
  return <Container align={align}>{children}</Container>;
};

export default Align;
