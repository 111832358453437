import React from 'react'
import { Phone } from 'react-feather'
import styled from 'styled-components'
import { HEXCOLORS } from '../../styles'
import Anchor from '../Anchor'

const Wrapper = styled.div`
  padding: 32px;
  text-align: center;
  margin-bottom: 32px;
  @media all and (max-width: 768px) { 
    padding: 32px 16px;
  }
`

const Flex = styled.div`
  display: inline-flex;
  justify-content: center;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  padding: 48px;
  border-radius: 16px;
  flex-wrap: wrap;
  @media all and (max-width: 768px) {
    padding: 0 0 32px;
    box-shadow: none;
  }
`

const Item = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  align-items: center;
`


const Title = styled.h3`
  font-weight: 600;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 20px;  
  }
`


const Logo = styled.img`
  margin-bottom: 32px;
  width: 64px;
  height: 64px;

  @media (max-width: 768px) {  
    margin-bottom: 16px;
  }

`

const Heading = styled.h2`
`

const Specialisaties = ({ content }) => {
  return (
    <>
      <Anchor name="specialisaties" />
      <Wrapper>
        <Heading>Onze specialisaties</Heading>
        <Flex>
          {content.map(specialisatie => (
            <Item key={specialisatie.id}>
                    <Logo src={specialisatie.logo.url} alt={specialisatie.titel} />
              <Title>{specialisatie.titel}</Title>
            </Item>
          ))}
        </Flex>
      </Wrapper>
    </>
  )
}

export default Specialisaties
