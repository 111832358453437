import React from 'react'
import Head from 'next/head'
import styled from 'styled-components'
import Button from '../components/Button'
import Flex from '../components/Flex'
import { GraphQLClient } from 'graphql-request'
import Footer from '../components/Footer'
import Team from '../components/home/Team'
import Behandelingen from '../components/home/Behandelingen'
import Specialisaties from '../components/home/Specialisaties'
import Contact from '../components/home/Contact'
import Anchor from '../components/Anchor'
import MarkdownRender from '../components/MarkdownRender'
import Image from 'next/image'
import { getPlaiceholder } from 'plaiceholder'
import { NextSeo } from 'next-seo'
import Nieuws from '../components/home/Nieuws'

const Main = styled.main`
  @media all and (max-width: 1024px) {
    margin-top: 76px;
  }
  max-width: 100vw;
  overflow: hidden;
`

const Left = styled.div`
  width: 50vw;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 768px) {
    width: 100vw;
  }
`

const Right = styled.div`
  width: 50vw;
  background-color: hsla(220, 46%, 86%, 1);
  position: relative;
  background-image: radial-gradient(
      at 4% 90%,
      hsla(18, 66%, 94%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 70% 25%, hsla(338, 54%, 89%, 1) 0px, transparent 50%),
    radial-gradient(at 48% 32%, hsla(240, 55%, 91%, 1) 0px, transparent 50%),
    radial-gradient(at 65% 68%, hsla(197, 7%, 85%, 1) 0px, transparent 50%),
    radial-gradient(at 96% 97%, hsla(17, 29%, 97%, 1) 0px, transparent 50%),
    radial-gradient(at 5% 84%, hsla(127, 58%, 96%, 1) 0px, transparent 50%);
  background-size: cover;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center bottom;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 1024px) {
    min-height: 60vh;
  }
  @media all and (max-width: 768px) {
    width: 100vw;
    min-height: 30vh;
    padding: 32px;
  }
`

const Inner = styled.div`
  padding: 16px;
  max-width: 70%;
  @media all and (max-width: 1440px) {
    max-width: 70%;
  }
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: self-start;
  @media all and (max-width: 768px) {
    max-width: 100%;
  }
  @media all and (max-width: 1080px) {
    min-height: 50vh;
    max-width: 80%;
    text-align: left;
    padding-right: 32px;
  }
`

const Hero = styled.div`
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-left: -5vw;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: 60vh;

  @media all and (max-width: 1024px) {
    height: 50vh;
  }

  @media all and (max-width: 768px) {
    margin-left: 0;
    position: relative;
    width: 100%;
    height: 40vh;
  }
`

const Title = styled.h1`
  font-size: 24px;
  margin: 0;
  padding: 0;
  font-family: Nexa;
  font-weight: normal;
`

const Subtitle = styled.h1`
  font-size: 48px;
  margin: 0;
  padding: 0;
  font-family: Nexa;
  font-weight: 500;
  @media all and (max-width: 768px) {
    font-size: 38px;
  }
`

export default function Home({
  content,
  team,
  specialisaties,
  behandelingen,
  contact,
  nieuws,
}) {
  return (
    <>
      <NextSeo
        title='Kine Itterbeek'
        description='Last van een fysiek ongemak of letsel? Dan bezorgen wij u de gepaste en kwalitatieve zorg. Na een uitgebreid onderzoek, zullen wij in samenspraak de optimale behandeling starten. Gespecialiseerd in ademhalingstherapie, manuele therapie en sportkinesitherapie.'
        canonical='https://kine-itterbeek.vercel.app/'
        openGraph={{
          url: 'https://kine-itterbeek.vercel.app/',
          title: 'Kine Itterbeek',
          description: 'Uw zorgen.. zijn onze zorgen!',
          images: [
            {
              url: 'https://kine-itterbeek.vercel.app/og-image-01.png',
              width: 800,
              height: 600,
              alt: 'Kine Itterbeek - Uw zorgen.. zijn onze zorgen!',
              type: 'image/png',
            },
            {
              url: 'https://kine-itterbeek.vercel.app/og-image-02.png',
              width: 900,
              height: 800,
              alt: 'Kine Itterbeek - Uw zorgen.. zijn onze zorgen!',
              type: 'image/png',
            },
          ],
          site_name: 'KINE ITTERBEEK',
        }}
        additionalMetaTags={[
          {
            property: 'dc:creator',
            content: 'Nakamas',
          },
          {
            name: 'application-name',
            content: 'Kine Itterbeek',
          },
          {
            httpEquiv: 'x-ua-compatible',
            content: 'IE=edge; chrome=1',
          },
        ]}
      />
      <Head>
        <title>Kine Itterbeek</title>
      </Head>
      <Main>
        <Anchor name='home' />
        <Flex>
          <Left>
            <Inner>
              <Title>{content.titel}</Title>
              <Subtitle>{content.subtitel}</Subtitle>
              <MarkdownRender
                source={content.beschrijving}
                styling={{ textAlign: 'left' }}
              />
              <Button goTo='#contact'>{content.cta}</Button>
            </Inner>
          </Left>
          <Right>
            <Hero>
              <Image
                src={`https://media.graphcms.com/output=format:jpg/resize=,width:960,height:640,fit:crop/${content.afbeelding.handle}`}
                layout='fill'
                alt='Kinepraktijk Itterbeek'
                objectFit='cover'
                quality={100}
                blurDataURL={content.afbeelding.base64}
                placeholder='blur'
              />
            </Hero>
          </Right>
        </Flex>
        <Nieuws content={nieuws} />
        <Specialisaties content={specialisaties} />
        <Team content={team} />
        <Behandelingen content={behandelingen} />
        <Contact content={{ ...contact, team }} />
      </Main>
      <Footer content={{ ...contact, team }} />
    </>
  )
}

export async function getStaticProps() {
  const graphcms = new GraphQLClient(
    'https://api-eu-west-2.hygraph.com/v2/cl92ke1iy2tpi01ur7vkx5v4b/master',
  )

  const {
    homes,
    teams,
    specialisaties,
    behandelings,
    contacts,
    nieuwsModel,
  } = await graphcms.request(
    `
    { 
      homes(orderBy: createdAt_ASC) {
        id,
        titel,
        subtitel,
        beschrijving,
        cta,
        afbeelding {
          url, 
          handle,
        }
    },
      teams(orderBy: createdAt_ASC) {
        id,
        naam,
        beschrijving,
        tel,
        afbeelding {
          url, 
          handle,
        }
    },
    specialisaties(orderBy: createdAt_ASC) {
      id,
      titel,
      logo {
        url, 
        handle,
      }
  },
  behandelings(orderBy: createdAt_ASC) {
    id,
    titel,
    beschrijving,
    extra,
    logo {
      url, 
      handle,
    }
},
contacts(orderBy: createdAt_ASC) {
  id,
  titel,
  titelFormulier,
  subtitelFormulier,
  email,
  telefonischContactBeschrijving,
  adres,
  openingsuren,
  facebook,
  instagram,
},
nieuwsModel(orderBy: createdAt_ASC) {
  id,
  titel,
  beschrijving,
  createdAt,
},
  }
    `,
  )

  const homeImg = homes[0].afbeelding.url
  const { base64 } = await getPlaiceholder(homeImg, { size: 10 })

  homes[0].afbeelding.base64 = base64

  const addBase64Images = arr => {
    const promises = arr.map(async item => {
      const src = item.afbeelding.url
      const { base64 } = await getPlaiceholder(src, { size: 10 })
      item.afbeelding.base64 = base64
      return item
    })
    return Promise.all(promises)
  }

  const teamsWithBase64Images = await addBase64Images(teams)

  return {
    props: {
      content: homes[0],
      team: teamsWithBase64Images,
      specialisaties,
      behandelingen: behandelings,
      contact: contacts[0],
      nieuws: nieuwsModel,
    },
  }
}
