import React, { useState } from 'react'
import styled from 'styled-components'
import Flex from './Flex'
import Align from './Align'
import { HEXCOLORS } from '../styles'
import { AlertCircle, CheckCircle } from 'react-feather'

const Button = styled.button`
  background-color: ${HEXCOLORS.primary};
  color: white;
  font-weight: 500;
  border: 0;
  border-radius: 4px;
  padding: 16px 24px 12px;
  font-size: 16px;
  margin-top: 16px;
  display: inline-flex;
  cursor: pointer;
  font-family: Nexa;
  font-weight: 600;
  transition: background-color 0.3s ease;

  ${props => props.disabled && `opacity: 0.5; pointer-events: none;`};

  &:hover {
    background-color: ${HEXCOLORS.primaryHover};
  }
`

const FormWrapper = styled.form`
  position: relative;
`

const FormInput = styled.input`
  border: 0;
  display: block;
  padding: 12px;
  font-size: 16px;
  width: 100%;
  box-shadow: inset 0 2px 2px rgb(73 89 106 / 10%),
    inset 0 0 0 1px rgb(10 30 44 / 6%);
  border-radius: 4px;
  background-color: white;

  &&:focus {
    outline: 0;
  }
`

export const Small = styled.p`
  font-size: 12px;
  margin: 4px 0;
`

const Label = styled.label`
  margin-bottom: 2px;
  display: block;
`

const Error = styled(Small)`
  color: ${HEXCOLORS.error};
  margin-bottom: 8px;
`

const TextArea = styled.textarea`
  resize: no;
  display: block;
  margin-top: 0;
  width: 100%;
  padding: 12px;
  font-family: 'Open Sans';
  height: 125px;
  font-size: 16px;
  margin-bottom: 0;
  border: 0;
  box-shadow: inset 0 2px 2px rgb(73 89 106 / 10%),
    inset 0 0 0 1px rgb(10 30 44 / 6%);
  border-radius: 4px;
  background-color: white;
  min-height: 80px;
  max-width: 100%;

  &&:focus {
    outline: 0;
  }
  ::placeholder {
    font-family: 'Open Sans';
  }
`

const Item = styled.div`
  padding: 8px;
  align-self: start;
  width: 100%;
`

const Banner = styled.p`
  padding: 16px;
  display: block;
  width: 100%;
  width: calc(100% - 16px);
  margin: 8px;
  display: flex;
  align-items: center;
`

const SuccessBanner = styled(Banner)`
  background-color: ${HEXCOLORS.success};
`

const ErrorBanner = styled(Banner)`
  background-color: ${HEXCOLORS.error};
`

const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const ContactForm = () => {
  const [name, setName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [tel, setTel] = useState('')
  const [errors, setErrors] = useState({})
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const [success, setSuccess] = useState('')

  const handleOnSubmit = async e => {
    setSuccess('')
    setError('')
    e.preventDefault()
    let errors = []
    let errorCount = 0
    if (!name) {
      errors = { ...errors, name: 'Naam mag niet leeg zijn' }
      errorCount += 1
    }
    if (!email) {
      errors = { ...errors, email: 'E-mail mag niet leeg zijn' }
      errorCount += 1
    }
    if (!tel) {
      errors = { ...errors, tel: 'Telefoonnummer mag niet leeg zijn' }
      errorCount += 1
    } else if (!regex.test(email)) {
      errors = { ...errors, email: 'E-mail is niet correct' }
      errorCount += 1
    }

    if (errorCount > 0) {
      setErrors(errors)
    } else {
      setIsLoading(true)
      const res = await fetch('/api/sendEmail', {
        body: JSON.stringify({
          email,
          name,
          message,
          phone: tel,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      })

      const { error } = await res.json()
      if (error) {
        setIsLoading(false)
        setError(error)
        return
      }
      setIsLoading(false)
      setName('')
      setEmail('')
      setMessage('')
      setTel('')
      setSuccess('We hebben uw bericht goed ontvangen!')
    }
  }

  const clearErrors = () => {
    setErrors({})
  }

  return (
    <React.Fragment>
      <FormWrapper>
        <Flex>
          <Item>
            <Label htmlFor='name'>Naam*</Label>
            <FormInput
              name='name'
              id='name'
              value={name}
              onChange={e => setName(e.target.value)}
              onFocus={clearErrors}
            />
            {errors.name && <Error>{errors.name}</Error>}
          </Item>
          <Item>
            <Label htmlFor='email'>E-mail*</Label>
            <FormInput
              name='email'
              id='email'
              value={email}
              onChange={e => setEmail(e.target.value)}
              onFocus={clearErrors}
            />
            {errors.email && <Error>{errors.email}</Error>}
          </Item>
        </Flex>
        <Flex>
          <Item>
            <Label htmlFor='tel'>Tel*</Label>
            <FormInput
              name='tel'
              id='tel'
              value={tel}
              onChange={e => setTel(e.target.value)}
              onFocus={clearErrors}
            />
            {errors.tel && <Error>{errors.tel}</Error>}
          </Item>
        </Flex>
        <Flex>
          <Item>
            <Label htmlFor='message'>Uw bericht</Label>
            <TextArea
              placeholder='Typ hier uw bericht'
              id='message'
              name='message'
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
          </Item>
        </Flex>
        {error && (
          <ErrorBanner>
            <AlertCircle
              size='16'
              color={HEXCOLORS.text}
              style={{ marginRight: '8px' }}
            />
            {error}, probeer het later opnieuw.
          </ErrorBanner>
        )}
        {success && (
          <SuccessBanner>
            <CheckCircle
              size='16'
              color={HEXCOLORS.text}
              style={{ marginRight: '8px' }}
            />
            {success}
          </SuccessBanner>
        )}

        <Flex>
          <Item>
            <Align align='center'>
              <Button onClick={handleOnSubmit} disabled={isLoading}>
                Verstuur
              </Button>
            </Align>
          </Item>
        </Flex>
      </FormWrapper>
    </React.Fragment>
  )
}

export default ContactForm
