const use3DSlider = () => {
  const onProgress = (swiper) => {
    const scaleStep = 0.2
    const zIndexMax = swiper.slides.length
    for (let i = 0; i < swiper.slides.length; i += 1) {
      const slideEl = swiper.slides[i]
      const progress = slideEl.progress
      const absProgress = Math.abs(progress)
      let modify = 1
      if (absProgress > 1) {
        modify = (absProgress - 1) * 0.3 + 1
      }
      const translateX = `${progress * modify * 50}%`
      const scale = 1 - absProgress * scaleStep
      const zIndex = zIndexMax - Math.abs(Math.round(progress))
      if (slideEl) {
        slideEl.style.transform = `translateX(${translateX}) scale(${scale})`
        slideEl.style.zIndex = zIndex.toString()
        slideEl.style.opacity = (1 - absProgress / 3).toString()
      }
    }
  }

  const onSetTransition = (swiper, transition) => {
    for (let i = 0; i < swiper.slides.length; i += 1) {
      const slideEl = swiper.slides[i]
      slideEl.style.transitionDuration = `${transition}ms`
    }
  }

  return { onProgress, onSetTransition }
}

export default use3DSlider
