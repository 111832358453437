import React from 'react'
import { Facebook, Instagram, Mail, Phone } from 'react-feather'
import styled from 'styled-components'
import { HEXCOLORS } from '../../styles'

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -1px;
`

const Item = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 8px;
  cursor: pointer;
  margin: 16px;
  transition: all 0.3s ease-in-out;
  &:hover {
    svg {
      fill: ${HEXCOLORS.primaryBackground};
    }
  }
  @media all and (max-width: 1080px) {
    margin: 4px;
  }
  @media all and (max-width: 768px) {
    padding: 4px;
  }
`

const Heading = styled.p`
  font-weight: 600;
  display: none;
  color: white;
`

const Icons = ({ content, isSmall }) => (
  <Flex>
    <Item
      href={content.instagram}
      target='_blank'
      alt='instagram'
      name='facebook'
    >
      <Heading>Instagram</Heading>
      <Instagram size={isSmall ? 16 : 32} color={HEXCOLORS.primary} />
    </Item>
    <Item
      href={content.facebook}
      target='_blank'
      alt='facebook'
      name='facebook'
    >
      <Heading>Facebook</Heading>
      <Facebook size={isSmall ? 16 : 32} color={HEXCOLORS.primary} />
    </Item>
    <Item
      href={`mailto:${content.email}`}
      target='_blank'
      alt='email'
      name='email'
    >
      <Heading>{content.email}</Heading>
      <Mail size={isSmall ? 16 : 32} color={HEXCOLORS.primary} />
    </Item>
  </Flex>
)

export default Icons
