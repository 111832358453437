import React, { useState } from 'react'
import styled from 'styled-components'
import { Phone } from 'react-feather'
import { Navigation, Pagination } from 'swiper'
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react'
import use3DSlider from '../hooks/use3DSlider'
import { HEXCOLORS } from '../styles'
import { EqualHeight, EqualHeightElement } from 'react-equal-height/clean'
import Image from 'next/image'

const Name = styled.h3`
  color: ${HEXCOLORS.text};
  font-weight: 600;
  margin: 0;
`

const Img = styled.div`
  overflow: hidden;
  will-change: transform;
  position: relative;
  border-radius: 50%;
  margin-bottom: 32px;
  background-image: ${props => props.src && `url(${props.src})`};
  background-position: center;
  background-size: cover;
  width: 100%;
  padding-bottom: 100%;
`

const Member = styled.div`
  border-radius: 16px;
  overflow: hidden;
  padding: 32px;
  margin: 16px 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 01);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Tel = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${HEXCOLORS.primary};
  margin: 16px -32px -32px;
  padding: 16px;
`

const TelLink = styled.a`
    margin-left: 8px;
    color: ${HEXCOLORS.text}
    color: ${HEXCOLORS.primary};
`

const Description = styled.div`
  padding: 16px 0 0;
  height: 100%;
  text-align: left;
  font-size: 14px;
`

export const Item = styled.div`
  display: flex;
  margin-bottom: 16px;
  position: relative;
  align-items: center;
  width: 50%;
  padding: 16px;
  opacity: 0;

  @media all and (max-width: 1080px) {
    width: 100%;
  }
  @media all and (max-width: 768px) {
    padding: 0px;
  }
`

const Showcase = ({ items }) => {
  const [activeSlide, setActiveSlide] = useState(1)

  const { onProgress, onSetTransition } = use3DSlider()

  const onSlideChange = swiper => {
    setActiveSlide(swiper.activeIndex + 1)
  }

  return (
    <EqualHeight>
      <SwiperComponent
        modules={[Navigation, Pagination]}
        grabCursor={true}
        watchSlidesProgress={true}
        slidesPerView={1.2}
        centeredSlides={true}
        onProgress={onProgress}
        onSetTransition={onSetTransition}
        onSlideChange={onSlideChange}
      >
        {items.map((item, index) => {
          return (
            <SwiperSlide tag='li' key={`swiper_slide_${index}`}>
              <Member key={item.id}>
                <Img>
                  <Image
                    src={`https://media.graphcms.com/output=format:jpg/resize=,width:540,height:540,fit:crop/${item.afbeelding.handle}`}
                    layout='fill'
                    alt={item.naam}
                    objectFit='cover'
                    quality={75}
                    placeholder='blur'
                    blurDataURL={item.afbeelding.base64}
                  />
                </Img>
                <Name>{item.naam}</Name>
                <Tel>
                  <Phone size={16} color={HEXCOLORS.primary} />
                  <TelLink href={`tel:{item.tel}$`}>{item.tel}</TelLink>
                </Tel>
              </Member>
              <EqualHeightElement name='showcase-content'>
                <Item className='showcase-content'>
                  <Description>{item.beschrijving}</Description>
                </Item>
              </EqualHeightElement>
            </SwiperSlide>
          )
        })}
      </SwiperComponent>
    </EqualHeight>
  )
}

export default Showcase
