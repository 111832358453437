import React from 'react'
import { Phone } from 'react-feather'
import styled from 'styled-components'
import { HEXCOLORS } from '../../styles'
import Anchor from '../Anchor'
import Showcase from '../Showcase'
import Image from 'next/image'

export const Wrapper = styled.div`
  padding: 32px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: radial-gradient(
      at 85% 56%,
      hsla(57, 81%, 71%, 0.23) 0px,
      transparent 50%
    ),
    radial-gradient(at 21% 67%, hsla(286, 73%, 90%, 0.19) 0px, transparent 50%),
    radial-gradient(at 94% 68%, hsla(273, 83%, 75%, 0.19) 0px, transparent 50%),
    radial-gradient(at 62% 41%, hsla(150, 62%, 79%, 0.06) 0px, transparent 50%),
    radial-gradient(at 94% 2%, hsla(341, 81%, 64%, 0.14) 0px, transparent 50%),
    radial-gradient(at 63% 97%, hsla(266, 69%, 91%, 0.21) 0px, transparent 50%),
    radial-gradient(at 54% 37%, hsla(129, 61%, 60%, 0.11) 0px, transparent 50%);

  @media all and (max-width: 768px) {
    padding: 32px 0;
  }
`

export const Members = styled.div`
  padding: 0 32px;
  display: flex;
  max-width: 1280px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  @media all and (max-width: 768px) {
    padding: 0;
  }
`

export const Member = styled.div`
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 16px;
  overflow: hidden;
  padding: 32px;
  margin: 16px;
  width: 248px;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media all and (max-width: 768px) {
    display: none;
  }
`

export const Name = styled.h3`
  color: ${HEXCOLORS.text};
  font-weight: 600;
  margin: 0;
`

export const Img = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 50%;
  margin-bottom: 32px;
  background-image: ${props => props.src && `url(${props.src})`};
  background-position: center;
  background-size: cover;
  width: 100%;
  padding-bottom: 100%;
`

export const Avatar = styled.div`
  border-radius: 50%;
  background-image: ${props => props.src && `url(${props.src})`};
  background-position: center;
  background-size: cover;
  width: 48px;
  height: 48px;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  z-index: 1;
  font-family: 'Nexa';
  font-weight: 600;
  font-size: 32px;
  color: ${HEXCOLORS.primary};
  background-color: ${HEXCOLORS.primaryBackground};
  border: 1px solid ${HEXCOLORS.primary};
  @media all and (max-width: 768px) {
    width: 32px;
    height: 32px;
  }
`

export const Heading = styled.h2``

export const Tel = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${HEXCOLORS.primary};
  margin: 16px -32px -32px;
  padding: 16px;
`

const Description = styled.div`
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  padding: 32px;
  border-radius: 16px;
  height: 100%;
  text-align: justify;
  @media all and (max-width: 1080px) {
    text-align: left;
  }
  @media all and (max-width: 768px) {
    font-size: 14px;
    padding: 16px;
  }
`

export const Item = styled.div`
  display: flex;
  margin-bottom: 16px;
  position: relative;
  align-items: center;
  width: 50%;
  padding: 16px;
  @media all and (max-width: 1080px) {
    width: 100%;
  }
  @media all and (max-width: 768px) {
    padding: 0px;
  }
`

export const TelLink = styled.a`
    margin-left: 8px;
    color: ${HEXCOLORS.text}
    color: ${HEXCOLORS.primary};
`

export const DescriptionWrapper = styled.div`
  text-align: left;
  margin: 0 auto;
  border-radius: 16px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 32px;
  flex-direction: column;

  @media all and (max-width: 768px) {
    padding: 0px;
  }
`

export const HighlightWrapper = styled.div`
  padding-top: 48px;
  max-width: 100%;
  margin-left: auto;
  position: relative;
  @media all and (max-width: 1080px) {
    padding: 32px;
  }
  @media all and (max-width: 768px) {
    padding: 32px 16px;
    display: none;
  }
`

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${HEXCOLORS.primaryBackground};
  padding: 8px;
  border-radius: 50%;
  margin-right: 8px;
  position: relative;
`

export const Team = ({ content }) => {
  return (
    <>
      <Anchor name='team' />
      <Wrapper>
        <Heading>Ons team</Heading>
        <Members>
          <Showcase items={content} />
          {content.map(member => (
            <Member key={member.id}>
              <Img>
                <Image
                  src={`https://media.graphcms.com/output=format:jpg/resize=,width:540,height:540,fit:crop/${member.afbeelding.handle}`}
                  layout='fill'
                  objectFit='cover'
                  quality={100}
                  alt={member.naam}
                  placeholder='blur'
                  blurDataURL={member.afbeelding.base64}
                />
              </Img>
              <Name>{member.naam}</Name>
              <Tel>
                <Phone size={16} color={HEXCOLORS.primary} />
                <TelLink href={`tel:{member.tel}$`}>{member.tel}</TelLink>
              </Tel>
            </Member>
          ))}
        </Members>
      </Wrapper>
      <HighlightWrapper>
        <DescriptionWrapper>
          {content.map(member => (
            <Item key={member.id}>
              <AvatarWrapper>
                <Avatar
                  src={`https://media.graphcms.com/output=format:jpg/resize=,width:96,height:96,fit:crop/${member.afbeelding.handle}`}
                />
              </AvatarWrapper>
              <Description>{member.beschrijving}</Description>
            </Item>
          ))}
        </DescriptionWrapper>
      </HighlightWrapper>
    </>
  )
}

export default Team
