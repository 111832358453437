import React from 'react'
import { MapPin, Phone } from 'react-feather'
import styled from 'styled-components'
import { HEXCOLORS } from '../../styles'
import Anchor from '../Anchor'
import Icons from '../contact/Icons'
import ContactForm from '../ContactForm'
import MarkdownRender from '../MarkdownRender'

const Wrapper = styled.div`
  text-align: center;
  padding: 64px;
  background-image: radial-gradient(
      at 85% 56%,
      hsla(57, 81%, 71%, 0.23) 0px,
      transparent 50%
    ),
    radial-gradient(at 21% 67%, hsla(286, 73%, 90%, 0.19) 0px, transparent 50%),
    radial-gradient(at 94% 68%, hsla(273, 83%, 75%, 0.19) 0px, transparent 50%),
    radial-gradient(at 62% 41%, hsla(240, 59%, 84%, 0.06) 0px, transparent 50%),
    radial-gradient(at 94% 2%, hsla(341, 81%, 64%, 0.14) 0px, transparent 50%),
    radial-gradient(at 63% 97%, hsla(266, 69%, 91%, 0.21) 0px, transparent 50%),
    radial-gradient(at 54% 37%, hsla(240, 92%, 24%, 0.14) 0px, transparent 50%);

  @media all and (max-width: 1080px) {
    padding: 32px;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  .box-white {
    background-color: white;
    padding: 24px;
    margin-bottom: 24px;
    border-radius: 12px;
  }
`

const Form = styled.div`
  width: 100%;
  text-align: left;
  max-width: 768px;
  @media (max-width: 768px) {
    padding: 0 32px 32px;
  }
`

const Left = styled.div`
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  @media all and (max-width: 768px) {
    width: 100vw;
    padding: 64px 32px;
  }
  flex-direction: column;
`

const Right = styled.div`
  width: 50vw;
  min-height: 50vh;
  display: flex;
  @media all and (max-width: 768px) {
    width: 100vw;
    text-align: center;
  }
  align-items: center;
`

const Heading = styled.h3`
  padding-left: 8px;
  font-weight: 600;
  margin: 0;
`

const P = styled.p`
  padding-left: 8px;
  margin: 0;
  margin-bottom: 16px;
`

const Heading2 = styled.h2`
  margin: 0;
`

const Heading3 = styled.h3`
  margin-bottom: 0;
`

const Nav = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  svg {
    margin-right: 8px;
  }
`

const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;

  svg {
    margin-right: 8px;
  }
`

const Tel = styled.span``

const Contact = ({ content }) => {
  return (
    <>
      <Anchor name='contact' />
      <Wrapper>
        <Flex>
          <Left>
            <Heading2>{content.titel}</Heading2>
            <MarkdownRender
              source={content.telefonischContactBeschrijving}
              styling={{ textAlign: 'center' }}
            />
            <Flex style={{ flexDirection: 'column' }} className='box-white'>
              {content.team.map(member => (
                <Flex key={member.id}>
                  <Link
                    href={`tel:${member.tel}`}
                    target='_blank'
                    alt='tel'
                    name='tel'
                    rel='noreferrer'
                    style={{ textDecoration: 'none' }}
                  >
                    <name>{member.naam}</name>&nbsp;-&nbsp;
                    <br />
                    <Phone
                      size={16}
                      bgcolor={HEXCOLORS.darkBlue}
                      color={HEXCOLORS.primary}
                    />
                    <Tel>{member.tel}</Tel>
                  </Link>
                </Flex>
              ))}
            </Flex>
            <Icons content={content} />
            <Heading3>Adres</Heading3>
            <Nav>
              <MapPin color={HEXCOLORS.text} size={16} />{' '}
              <Link
                href='https://www.google.com/maps/dir/?api=1&destination=Kerkstraat+40,+1701+Itterbeek&hl=nl'
                target='_blank'
                rel='noopener noreferrer'
              >
                {content.adres}
              </Link>
            </Nav>
          </Left>
          <Right>
            <Form>
              <Heading>{content.titelFormulier}</Heading>
              <P>{content.subtitelFormulier}</P>
              <ContactForm />
            </Form>
          </Right>
        </Flex>
      </Wrapper>
    </>
  )
}

export default Contact
